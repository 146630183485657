function ready() {
  // Menu
  (() => {
    const menuButton = document.querySelector('.navbar-toggler');
    const menuList = document.querySelector('.menu-mob');
    const closeButton = document.querySelector('.btn-close');

    menuButton.addEventListener('click', () => {
      let expanded = menuButton.getAttribute('aria-expanded') === 'true';
      menuButton.setAttribute('aria-expanded', !expanded);
      //menuButton.classList.toggle('menu__button--open');
      menuList.classList.toggle('active');
    });

    closeButton.addEventListener('click', () => {
      let expanded = menuButton.getAttribute('aria-expanded') === 'false';
      menuButton.setAttribute('aria-expanded', !expanded);
      //menuButton.classList.toggle('menu__button--open');
      menuList.classList.toggle('active');
    });

  })();

  loadScript("/assets/js/jquery.js", function () {

    $(".menu-mob a").on('click', function (e) {
      $(".menu-mob").removeClass('active');
    });


    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 500) {
        $("header").addClass("fixed");
      }
      else {
        $("header").removeClass("fixed");
      }
    });

    $(".services-menu a").on('click', function (e) {
      e.preventDefault();
      AOS.refreshHard();
      if (!$(this).hasClass("active")) {
        let data = $(this).attr('data-services');
        $(".services-menu a").removeClass("active");
        $(".services-list").removeClass("active");
        
        $("#" + data).addClass("active");
        $(this).addClass("active");
      }
      AOS.refreshHard();
    });

    $(".request-menu-wrap a").on('click', function (e) {
      e.preventDefault();
      if (!$(this).hasClass("active")) {
        let data = $(this).attr('data-forms');
        $(".request-menu-wrap a").removeClass("active");
        $(".request-form-wrap").removeClass("active");
        $("#" + data).addClass("active");
        $(this).addClass("active");
      }
    });

    $(".video-modal .btn-close").on('click', function (e) {
      const modal = document.querySelector('.video-modal');
      modal.classList.toggle('active');
      var video = $('.video-modal video').attr('src', '#');
      video.pause();
      video.currentTime = 0;
    });


    $('.news-useful-warp-item').on('click', function (e) {
      var $videoUrl = $(this).data('video');
      var video = $('.video-modal video').attr('src', $videoUrl);
      const modal = document.querySelector('.video-modal');
      modal.classList.toggle('active');
      video[0].load();
      video[0].play();
    });

    /* function loadVideo(element, src) {
      const source = document.createElement("source");
      source.src = src;
      element.appendChild(source);
      element.load();
      element.play();
    }

    $('.news-useful-warp-item').on('click', function (e) {
      var $videoUrl = $(this).data('video');
      $('.video-modal video').attr('src', $videoUrl);
      const video = document.getElementById("news-media");

      () => loadVideo(video, $videoUrl);

      const modal = document.querySelector('.video-modal');
      modal.classList.toggle('active');
    }); 
    
    function fetchVideoAndPlay(video) {
      fetch(video)
        .then(response => response.blob())
        .then(blob => {
          video.srcObject = blob;
          return video.play();
        })
        .then(_ => {
          // Video playback started ;)
        })
        .catch(e => {
          // Video playback failed ;(
        })
    }*/





    //When the document is ready
    $(function () {
      //This is triggered when the
      //user scrolls the page
      $(window).scroll(function () {
        //Checking if each items to animate are
        //visible in the viewport
        $(".reasons-advantage-count > span[data-max]").each(function () {
          inVisible($(this));
        });
      })
    });

    loadScript("/assets/js/jquery.marquee.min.js", function () {
      $('.marquee-left').marquee({
        duration: 50000,
        gap: 20,
        delayBeforeStart: 0,
        direction: 'left',
        duplicated: true,
        startVisible: true
      });

      $('.marquee-right').marquee({
        duration: 50000,
        gap: 20,
        delayBeforeStart: 0,
        direction: 'right',
        duplicated: true,
        startVisible: true
      });
    });

    loadScript("/assets/js/aos.js", function () {
      AOS.init({
        offset: 100,
      });
    });

    loadScript("/assets/js/slick.min.js", function () {
      $('.reviews-wrap, .news-company-wrap, .news-useful-warp').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        prevArrow: "<button type='button' class='slick-prev'></button>",
        nextArrow: "<button type='button' class='slick-next'></button>",
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
    });

  });
}

document.addEventListener("DOMContentLoaded", ready);

// Функция загрузки скриптов
function loadScript(url, callback) {
  var script = document.createElement("script");

  if (script.readyState) {  // IE
    script.onreadystatechange = function () {
      if (script.readyState == "loaded" ||
        script.readyState == "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {  // Другие браузеры
    script.onload = function () {
      callback();
    };
  }

  script.src = url;
  script.async = false; // TODO: Переделать что бы можно было менять
  document.getElementsByTagName("head")[0].appendChild(script);
}

function inVisible(element) {
  //Checking if the element is
  //visible in the viewport
  var WindowTop = $(window).scrollTop();
  var WindowBottom = WindowTop + $(window).height();
  var ElementTop = element.offset().top;
  var ElementBottom = ElementTop + element.height();
  //animating the element if it is
  //visible in the viewport
  if ((ElementBottom <= WindowBottom) && ElementTop >= WindowTop)
    animate(element);
}

function animate(element) {
  //Animating the element if not animated before
  if (!element.hasClass('ms-animated')) {
    $(element).text('');
    var maxval = element.data('max');
    var html = element.html();

    element.addClass("ms-animated");
    $({
      countNum: element.html()
    }).animate({
      countNum: maxval
    }, {
      //duration 5 seconds
      duration: 3000,
      easing: 'linear',
      step: function () {
        element.html(groupNums(Math.floor(this.countNum) + html));
      },
      complete: function () {
        element.html(groupNums(this.countNum + html));
      }
    });
  }
}

//Разделить число на тысячи пробелом
groupNums = (
  function () {
    var r = /(\d{3})/g;
    return function (text) {
      text = String(text);
      return text.split("").reverse().join("").replace(r, "$1 ").split("").reverse().join("");
    }
  }
)();

// Lazyload для background-image
document.addEventListener("DOMContentLoaded", function () {
  var lazyloadImages;

  if ("IntersectionObserver" in window) {
    lazyloadImages = document.querySelectorAll(".lazy");
    var imageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
          image.classList.remove("lazy");
          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach(function (image) {
      imageObserver.observe(image);
    });
  } else {
    var lazyloadThrottleTimeout;
    lazyloadImages = document.querySelectorAll(".lazy");

    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function () {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function (img) {
          if (img.offsetTop < (window.innerHeight + scrollTop)) {
            img.src = img.dataset.src;
            img.classList.remove('lazy');
          }
        });
        if (lazyloadImages.length == 0) {
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
      }, 20);
    }

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
  }
})


// MODAL
function closeModal(modal) {
  const backdrop = document.querySelector(".modal-backdrop.fade.show");
  document.body.classList.remove("modal-open");
  modal.setAttribute("aria-hidden", "true");
  backdrop.classList.remove("show");
  modal.classList.remove("show");
  modal.style.display = "none";
  backdrop.remove();
}

function openModal(modal) {
  const backdrop = document.createElement("div");
  backdrop.classList.add("modal-backdrop", "fade", "show");
  document.body.classList.add("modal-open");
  document.body.appendChild(backdrop);
  modal.style.display = "block";
  modal.setAttribute("aria-hidden", "false", "show");
  modal.classList.add("show");
}

window.addEventListener("load", () => {
  document.querySelectorAll("[data-toggle=modal]").forEach((element) => {
    element.addEventListener("click", () => {
      const target = element.dataset.target;
      const modal = document.querySelector(target);
      if (modal) {
        openModal(modal);
      }
    });
  });

  document.querySelectorAll("[data-dismiss=modal]").forEach((element) => {
    element.addEventListener("click", () => {
      const modal = document.querySelector(".modal.show");
      if (modal) {
        closeModal(modal);
      }
    });
  });

  document.querySelectorAll(".modal").forEach((modal) => {
    modal.addEventListener("click", (event) => {
      if (event.target === modal) {
        closeModal(modal);
      }
    });
  });
});
